import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap";

//Import Breadcrumb

//i18n
import { withTranslation } from "react-i18next";
import { getAllEvents, getAllOrganizer, getBookingByEventId, getForumBySlug } from "api";
import Breadcrumbs from "components/Common/Breadcrumb";
import Select from "react-select";
import TableContainer from "../../../components/Common/TableContainer";
import moment from "moment";

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { filter } from "lodash";

const pagination = true;
const paginationPageSize = 500;
const paginationPageSizeSelector = [200, 500, 1000];
const BookingAllStatus = props => {
    //meta title
    document.title = "Booking Status List | EventoX";
    const [eventList, setEventList] = useState([])
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [bookingList, setBookingList] = useState([])
    const [gridParams, setGridParams] = useState(null)
    const [gridApi, setGridApi] = useState(null);
    const gridApiRef = useRef(null);


    const [reports, setReports] = useState([
        { title: "Success Booking", iconClass: "bx-copy-alt", description: 0,col:3 },
        { title: "Pending Booking", iconClass: "bx-copy-alt", description: 0 ,col:3},
        { title: "Failed Booking", iconClass: "bx-copy-alt", description: 0 ,col:3},
        { title: "Total Booking", iconClass: "bx-archive-in", description: 0 ,col:3},

        { title: "Organizer Revenue", iconClass: "bx-purchase-tag-alt", description: 0,col:2 },
        { title: "Eventox Revenue", iconClass: "bx-purchase-tag-alt", description: 0,col:2 },
        { title: "Strip Payment Fee", iconClass: "bx-purchase-tag-alt", description: 0,col:2 },
        { title: "Total Discount", iconClass: "bx-purchase-tag-alt", description: 0,col:2 },
        { title: "Total Amount", iconClass: "bx-purchase-tag-alt", description: 0,col:4 },

        { title: "No Of Ticket Sale", iconClass: "bx-archive-in", description: 0,col:3 },
        { title: "No. of Adult", iconClass: "bx-archive-in", description: 0,col:3 },
        { title: "No. of Children above 5", iconClass: "bx-archive-in", description: 0,col:3 },
        { title: "No. of Children below 5", iconClass: "bx-archive-in", description: 0,col:3 },

    ])

    useEffect(() => {
        fetchAll()
    }, [])

    const fetchAll = async () => {
        try {

            const respAllEvent = await getAllEvents();
            const eventOptions = respAllEvent.map((item) => {
                return {
                    value: item.eventId,
                    label: item.eventName,
                    ticketFee: item.ticketFee,
                }
            })
            setEventList(eventOptions);





        } catch (error) {
            console.log(error);
        }
    }

    const fetchBookingByEvent = async (data) => {
        const { value } = data;
        const getBookingResp = await getBookingByEventId(value)
        setBookingList(getBookingResp)
        const activeBooking = getBookingResp.filter((item) => item.bookingStatus === 'confirmed');
        console.log("🚀 ~ fetchBookingByEvent ~ activeBooking:", activeBooking)
        const cancelledBooking = getBookingResp.filter((item) => item.bookingStatus === 'failed');
        const pendingBooking = getBookingResp.filter((item) => item.bookingStatus === 'pending');
        const currency = activeBooking[0]?.currency
        
        const ticketPurchased = activeBooking.filter((item) => item.bookedBy === "0")
        console.log("🚀 ~ fetchBookingByEvent ~ ticketPurchased:", ticketPurchased)
        const totalRevenue = ticketPurchased.reduce((acc, item) => acc + item.totalTicketAmount, 0)
        console.log("🚀 ~ fetchBookingByEvent ~ totalRevenue:", totalRevenue)
        const totalDiscount = ticketPurchased.reduce((acc, item) => acc + item.discountAmount, 0)
        const processingFee = ticketPurchased.reduce((acc, item) => acc + item.processingFee, 0)
        const ticketFee = (data?.ticketFee || 0) * ticketPurchased.length
        const totalEventoxRevenue = parseFloat(processingFee) + parseFloat(ticketFee)
        const totalAmount = parseFloat(totalRevenue) + parseFloat(processingFee) + parseFloat(ticketFee)
        let totalAdultBooking = 0;
        let totalChildBooking = 0;
        let totalSeniorBooking = 0;
        ticketPurchased.forEach((item) => {
            if (item.bookingObject && item.bookingObject.ticketListCount) {
                totalAdultBooking += parseInt(item.bookingObject.ticketListCount[0]?.count || 0, 10);

                totalSeniorBooking += parseInt(item.bookingObject.ticketListCount[1]?.count || 0, 10);
                totalChildBooking += parseInt(item.bookingObject.ticketListCount[2]?.count || 0, 10);
            }

        })
        setReports([
            { title: "Success Booking", iconClass: "bx-copy-alt", description: activeBooking.length,col:3 },
            { title: "Pending Booking", iconClass: "bx-copy-alt", description: pendingBooking.length,col:3 },
            { title: "Failed Booking", iconClass: "bx-copy-alt", description: cancelledBooking.length,col:3 },
            { title: "Total Booking", iconClass: "bx-archive-in", description: getBookingResp.length,col:3 },

            { title: "Organizer Revenue", iconClass: "bx-purchase-tag-alt", description: totalRevenue, col:2},
            { title: "Eventox Revenue", iconClass: "bx-purchase-tag-alt", description: ticketFee.toFixed(2), col:2},
            { title: "Strip Payment Fee", iconClass: "bx-purchase-tag-alt", description: processingFee.toFixed(2), col:2},
            { title: "Total Discount", iconClass: "bx-purchase-tag-alt", description: totalDiscount, col:2},
            { title: "Total Amount", iconClass: "bx-purchase-tag-alt", description: totalAmount.toFixed(2),col:4 },


            { title: "No Of Ticket Sale", iconClass: "bx-archive-in", description: ticketPurchased.length ,col:3},
            { title: "No. of Adult", iconClass: "bx-archive-in", description: totalAdultBooking ,col:3},
            { title: "No. of Children above 5", iconClass: "bx-archive-in", description: totalSeniorBooking ,col:3},
            { title: "No. of Children below 5", iconClass: "bx-archive-in", description: totalChildBooking ,col:3},

        ])

        console.log("🚀 ~ fetchBookingByEvent ~ getBookingResp:", getBookingResp)

    }
    const columns = useMemo(
        () => [

            {
                headerName: 'Booked No',
                field: 'bookingNo',
                filter: true

            },
            {
                headerName: 'Booked By',
                cellRenderer: params => {
                    return params.data.bookingObject.primaryContactDetails.firstName + ' ' + params.data.bookingObject.primaryContactDetails.lastName
                },
                filter: true

            },
            {
                headerName: 'Status',
                field: 'bookingStatus',
                filter: true,
                

            },
           
            {
                headerName: 'Ticket Count',
                field: 'totalTicketCount',
                filter: true,

            },
            {
                headerName: 'Ticket Amount',
                field: 'totalTicketAmount',
                filter: true,

            },
            {
                headerName: 'Discount Amount',
                field: 'discountAmount',
                filter: true,

            },
            {
                headerName: 'Grand Total',
                field: 'grandTotal',
                filter: true,

            },


            {
                headerName: 'Name',
                field: 'Contact.firstName',
            },
            {
                headerName: 'Email',
                field: 'Contact.email',
                filter: true

            },
            {
                headerName: 'Phone',
                field: 'Contact.phoneNo',
                filter: true

            },
            {
                headerName: 'Booking Date',
                cellRenderer: params => {
                    return moment(params.data.createdAt).format('DD-MMM-YYYY HH:mm:ss')
                },
                filter: true,
                sortable: true

            },



        ])



    const onGridReady = (params) => {
        gridApiRef.current = params.api // <= assigned gridApi value on Grid ready 
        setGridParams(params)
        setGridApi(params.api);

    }
    const getContextMenuItems = (params) => {
        const defaultItem = [
            'copy',
            'copyWithHeaders',
            'paste',
            'export',
            'separator',
        ]
        const customItems = [
            // {
            //     name: 'Delete',
            //     icon: '<i class="far fa-trash-alt"></i>',
            //     action: () => {
            //         setQueryToDelete(node?.data)
            //     }
            // },
        ]
        return [...defaultItem, ...customItems]
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title={props.t("Dashboards")}
                        breadcrumbItem={props.t("Dashboard")}
                    />

                    <Row>
                        <Col xl="12">
                            <Row>
                                {(reports || [])?.map((report, key) => (
                                    <Col md={report.col} key={"_col_" + key}>
                                        <Card className="mini-stats-wid">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">
                                                            {report.title}
                                                        </p>
                                                        <h4 className="mb-0">{report.description}</h4>
                                                    </div>
                                                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            <i className={"bx " + report.iconClass + " font-size-24"}></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12">

                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col xl="5">
                                            <div className="col-md-12">
                                                <Select
                                                    value={selectedEvent}
                                                    placeholder="Select Event"
                                                    onChange={(data) => {
                                                        setSelectedEvent(data)
                                                        fetchBookingByEvent(data)
                                                    }}
                                                    options={eventList}
                                                    width="100%"
                                                    className="select2-selection"
                                                />

                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div style={{
                                            height: '500px',
                                            width: '100%'
                                        }} className="ag-theme-quartz">
                                            <AgGridReact
                                                columnDefs={columns}
                                                rowData={bookingList}
                                                onGridReady={onGridReady}
                                                getRowId={({ data }) => data?.bookingId?.toString()}
                                                ref={gridApiRef}
                                                defaultColDef={{
                                                    floatingFilter: true,
                                                    filter: 'agTextColumnFilter',
                                                    sortable: true,
                                                    resizable: true,
                                                    enableRowGroup: true,
                                                }}
                                                sideBar={{ toolPanels: ['columns', 'filters'] }}
                                                rowGroupPanelShow="always"
                                                pagination={pagination}
                                                paginationPageSize={paginationPageSize}
                                                paginationPageSizeSelector={paginationPageSizeSelector}
                                                getContextMenuItems={getContextMenuItems}

                                            />
                                        </div>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};



export default withTranslation()(BookingAllStatus);

